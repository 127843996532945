import { Component, OnInit } from '@angular/core';
import { MenuitemService } from '../../services/auth/menuitem.service';
import { AuthenticationService } from '../../services/auth/authentication.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export var ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
    { path: '/user-profile', title: 'User Profile', icon: 'person', class: '' },
    { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '' },
    { path: '/typography', title: 'Typography', icon: 'library_books', class: '' },
    { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },
    { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '' },
    { path: '/corpi', title: 'Corpi', icon: 'dashboard', class: '' },
    { path: '/dati-catastali', title: 'Dati Catastali', icon: 'dashboard', class: '' },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    providers: [MenuitemService]
})
export class SidebarComponent implements OnInit {
    menuItems: any[];

    constructor(private serviceMenu: MenuitemService, private authenticationService: AuthenticationService) { }

    ngOnInit() {
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        //this.serviceMenu.getItem()
        //    .subscribe(data => {
        //        this.menuItems = data;
        //        console.log(this.menuItems);
        //    }, err => {
        //        console.log(err);
        //    });

        this.menuItems = this.authenticationService.currentUserValue.menuItem;
		ROUTES = this.menuItems;
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
}
