import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Utenti } from '../../models/utenti/utenti.model';
import moment = require('moment');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
/** login component*/
export class LoginComponent {

  loginForm: FormGroup;
  recoveryForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  errorStr: string = '';
  isPasswodrRecovery = false;
  errorRecovery = '';
  invalidLogin: boolean;
  /** login ctor */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.recoveryForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.loginForm.controls; }
  get fRecovery() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          var ut: Utenti;
          ut = <Utenti>data;
          this.loading = false;
          var dataFineValidita = moment(ut.dataFineValiditaPassword).format("MM-DD-YYYY");
          var dataCorrente = new Date();
          var date_diff_indays = function (date1, date2) {
            var dt2 = new Date(date2);
            var dt1 = new Date(date1);
            return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
          }
          if (ut.imponiModificaPassword) {
            this.router.navigate(['/CambiaPassword'], { queryParams: { idUtente: ut.idUtente, idCittadino: ut.idCittadino }, skipLocationChange: true });
          }

          else if (date_diff_indays(dataFineValidita,dataCorrente)>0) {
            this.router.navigate(['/CambiaPasswordScaduta'], { queryParams: { idUtente: ut.idUtente, idCittadino: ut.idCittadino }, skipLocationChange: true });
          }

          else {
            if (ut.idProfilo == 3) {
              this.router.navigate(['/Curriculum'], { queryParams: { idAnagrafica: ut.idCittadino }, skipLocationChange: true });
            } else {

              this.router.navigate([ut.menuItem.find(x => x.isDefault).path]);
            }
          }

        },
        error => {
          this.error = error;
          this.errorStr = error;
          this.loading = false;
        });
  }

  //login(form: NgForm) {
  //    let credentials = JSON.stringify(form.value);
  //    this.http.post("http://localhost:5000/api/auth/login", credentials, {
  //        headers: new HttpHeaders({
  //            "Content-Type": "application/json"
  //        })
  //    }).subscribe(response => {
  //        let token = (<any>response).token;
  //        localStorage.setItem("jwt", token);
  //        this.invalidLogin = false;
  //        this.router.navigate(["/"]);
  //    }, err => {
  //        this.invalidLogin = true;
  //    });
  //}

  logOut() {
    localStorage.removeItem("jwt");
  }

  onRecoveryPassword(value) {
    this.isPasswodrRecovery = value;
  }

  onSubmitRecoveryPassword() {
    this.submitted = true;
    this.errorRecovery = null;
    if (this.f.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.recoveryPassword(this.recoveryForm.get('username').value)
      .pipe(first())
      .subscribe(
        data => {
          this.isPasswodrRecovery = false;
          this.loading = false;
          this.submitted = false;
        },
        error => {
          this.errorRecovery = error;
          this.loading = false;
        });
  }

}
