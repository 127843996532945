import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utenti } from '../../models/utenti/utenti.model';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { UtentiGestione } from '../../models/utenti/utentiGestione.model';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Utenti>;
    public currentUser: Observable<Utenti>;
 // protected _notifications: NotificationsService;
  protected baseApiUrl = environment.apiUrl;
  protected apiUrl = this.baseApiUrl;

  constructor(private http: HttpClient,private _notifications: NotificationsService) {
        this.currentUserSubject = new BehaviorSubject<Utenti>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Utenti {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        
        return this.http.post<any>(`${environment.apiUrl}/Auth/Autenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
  }

  recoveryPassword(username: string) {
    return this.http.post<any>(`${environment.apiUrl}/Auth/recoveryPassword`, { username }, { withCredentials: true })
      .pipe(tap((data) => {
        this._notifications.create('Autenticazione', 'Recupero password avvenuto con successo.', NotificationType.Success, {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
        });

        this._notifications.create('Autenticazione', 'Password temporanea inviata all\'email: ' + data.email, NotificationType.Success, {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
        });
      }));
  }

}
