import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { LayoutComponentsModule } from './components/layoutcomponents.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { NumberDirective } from './directive/numbers-only.directive';

//import { NgxMaskModule } from 'ngx-mask'

//import { MaterialModule } from './material.module';
import {
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule, MatAutocompleteModule
} from '@angular/material';


import { LoginComponent } from './components/login/login.component';
import { CommonModule } from '@angular/common';
import { JwtInterceptor } from './_helpers/jwt-interceptor';
import { ErrorInterceptor } from './_helpers/error-interceptor';
import { AutocompleteService } from './services/autocomplete.service';
import { BrowserModule } from '@angular/platform-browser';




const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: '#F6A800',
	bgsPosition: POSITION.bottomRight,
	bgsSize: 40,
	bgsType: SPINNER.squareJellyBox, // background spinner type
	fgsType: SPINNER.chasingDots, // foreground spinner type
	pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
	pbThickness: 5, // progress bar thickness,
};


@NgModule({
  imports: [

        BrowserModule,
        BrowserAnimationsModule,
        CommonModule, FormsModule, ReactiveFormsModule,
        MatCardModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatAutocompleteModule, MatRadioModule,
		    LayoutComponentsModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule, DigitOnlyModule,
        SimpleNotificationsModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule
        //MaterialModule

    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
      LoginComponent,

      
      
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        //fakeBackendProvider,
        AutocompleteService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
