import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import {
  AutocompleteLuoghiResponse,
  AutocompleteQualificheResponse,
  AutocompleteNeetResponse,
  AutocompleteOrientatoriResponse,
  ItemNeet,
  ItemOrientatore,

} from "../interface/interface";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { Ente } from "../models/ente/ente.model";

const getLuoghiUrl = environment.apiUrl + "/Autocomplete/GetLuoghi";
const getLuoghiDataRifUrl =  environment.apiUrl + "/Autocomplete/GetLughiDataRif";
const getLuogoUrl = environment.apiUrl + "/Autocomplete/GetLuogo";
const getTIstatcpUrl = environment.apiUrl + "/Autocomplete/GetTIstatcp";
const getNeetsUrl = environment.apiUrl + "/Autocomplete/GetNeets";
const getNeetApiUrl = environment.apiUrl + "/Autocomplete/GetNeet";
const getDocentiApiUrl = environment.apiUrl + "/Autocomplete/GetDocenti";
const getOrientatoriApiUrl = environment.apiUrl + "/Autocomplete/GetOrientatori";
const getOrientatoreApiUrl = environment.apiUrl + "/Autocomplete/GetOrientatore";
const getTipologieDocumentoApiUrl = environment.apiUrl + "/Autocomplete/GetTipologieDocumento";
const getMacroEntiApiUrl = environment.apiUrl + "/Autocomplete/GetMacroEnti";
const getMacroEnteApiUrl = environment.apiUrl + "/Autocomplete/GetMacroEnte";
const getEntiUrl = environment.apiUrl + "/Autocomplete/GetEntis";
const getEnteApiUrl = environment.apiUrl + "/Autocomplete/GetEnte";
 
@Injectable(
  { providedIn: 'root' }
)
export class AutocompleteService {
  constructor(
    private http: HttpClient,
    private _notifications: NotificationsService
  ) {}
  private handleError<T>(operation = "operation", result?: T) {
    return (error: T): Observable<T> => {
      console.log("Errore " + operation);
      console.error(error); // log to console instead
      this._notifications.create(
        "Errore",
        "Si è verificato un errore operazione non completata.",
        NotificationType.Error,
        {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true
        }
      );
      return throwError(error);
    };
  }
  searchLuoghi(query: string): Observable<any> {
    return this.http
      .get<AutocompleteLuoghiResponse>(getLuoghiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }
  searchLuoghiDataRif(
    query: string,
    dataRif: string
  ): Observable<AutocompleteLuoghiResponse> {
    return this.http
      .get<AutocompleteLuoghiResponse>(getLuoghiDataRifUrl, {
        observe: "response",
        params: {
          match: query,
          dataRif: dataRif
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  getLuogo(codCatast: string): Observable<any> {
    return this.http
      .get<any>(getLuogoUrl, {
        observe: "response",
        params: {
          codCatastale: codCatast
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  searchQualifiche(query: string): Observable<AutocompleteQualificheResponse> {
    return this.http
      .get<AutocompleteQualificheResponse>(getTIstatcpUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  searchNeets(query: string): Observable<AutocompleteNeetResponse> {
    return this.http
      .get<AutocompleteNeetResponse>(getNeetsUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  getNeet(idCittadino: number): Observable<ItemNeet> {
    const url = `${getNeetApiUrl}/${idCittadino}`;
    return this.http
      .get<ItemNeet>(url)
      .pipe(catchError(this.handleError<any>("getOrientamento")));
  }

  getDocenti(query: string): Observable<any> {
    return this.http
      .get<any>(getDocentiApiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  searchOrientatori(query: string): Observable<any> {
    return this.http
      .get<any>(getOrientatoriApiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }
  getOrientatore(idOrientatore: number): Observable<ItemOrientatore> {
    const url = `${getOrientatoreApiUrl}/${idOrientatore}`;
    return this.http
      .get<ItemOrientatore>(url)
      .pipe(catchError(this.handleError<any>("getOrientatore")));
  }

  searchTipologieDocumento(query: string): Observable<any> {
    return this.http
      .get<any>(getTipologieDocumentoApiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }



  searchEnti(query: string): Observable<any> {
    return this.http
      .get<any>(getMacroEntiApiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }
  getMacroEnte(idMacroEnte: number): Observable<any> {
    const url = `${getMacroEnteApiUrl}/${idMacroEnte}`;
    return this.http
      .get<ItemOrientatore>(url)
      .pipe(catchError(this.handleError<any>("getMacroEnte")));
  }

  searchEntis(query: string): Observable<any> {
    return this.http
      .get<any>(getEntiUrl, {
        observe: "response",
        params: {
          match: query
        }
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  getEnte(idEnte: number): Observable<Ente> {
    const url = `${getEnteApiUrl}/${idEnte}`;
    return this.http
      .get<Ente>(url)
      .pipe(catchError(this.handleError<any>("getidEnte")));
  }
  
}

