import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { MenuItem } from '../../models/utenti/menuitem.model';
import { catchError, tap, retry, map } from 'rxjs/operators';

const apiBaseUrl = '/api/Auth';
const apiUrlGetMenuItem = apiBaseUrl + '/GetMenuItemWeb';

@Injectable()
export class MenuitemService {
    constructor(private http: HttpClient) {

    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    getItem(): Observable<MenuItem[]> {
        return this.http.get<MenuItem[]>(apiUrlGetMenuItem)
            .pipe(
                tap(),
                catchError(this.handleError('getItem', []))
            );
    }
}
